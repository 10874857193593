import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch, Route, useParams } from "react-router-dom";

// nodejs library that concatenates classes
import classnames from "classnames";
import * as yup from 'yup';
import { useFormik } from "formik";
import { Utils } from '../../common/Utils'
import axios from 'axios';
import AppGlobal from 'common/AppGlobal'

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Label,
} from "reactstrap";
// http://app1.best.systems:8190/api/8.0/module/
// /11223344/api/8.0/module/
// const url = Utils.DE('a02056eb17254e545607b3164237c7d0d63500b1bb8303ab140b5583b36e0452ae8bd05eefce8736ac6a8140b3fd3055815f34ddc009a297745aeb7db5536f507e1edb2ee24a71fc4198ffbdc6e90953dacea65ce1fe68819784ec7c2ebfb4509cdfd4cea7d4e6542a38d0d1f3d0e96f9c09981674b1d800ba');

// ?id=3066cf29-8c44-4be4-aa02-d9c876b5761f
const stringQuery = window.location.search;

var config = {
    url: '/11223344/api/8.0/module/',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer mysql_tn-crm/ab2bf9371567b65eba354a0f40925c9832db1a79'
    }
};

// console.log(Utils.EN('?class=Lead&'));

export default function FormPage() {
    const [height, setHeight] = useState(0);
    const [isSent, setIsSent] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isError, setIsError] = useState(false);

    // leadJSON
    const [leadJSON, setLeadJSON] = useState([]);
    const [customCheck1, setCustomCheck1] = useState(false);
    const [customCheck2, setCustomCheck2] = useState(false);
    const [customCheck3, setCustomCheck3] = useState(false);

    useEffect(() => {
        if (stringQuery != "") {
            axios.get(
                config.url + 'load?class=Lead&' + stringQuery.replace('?', ''), { headers: config.headers },
            ).then(res => {
                setLeadJSON(res.data.data[0]);
                if (res.data.data[0].ddLeadStatus.itemCode === 'UNSUB') {
                    setIsError(true);
                }
                setIsEdit(true);
            }).catch(error => console.log(error));
        }
        updateHeight();

    }, { height });

    function updateHeight() {
        setHeight(
            window.innerHeight
        )
    };

    let formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstName: isEdit ? leadJSON.firstName : '',
            lastName: isEdit ? leadJSON.lastName : '',
            dateOfBirth: isEdit ? leadJSON.dob : '',
            email: isEdit ? leadJSON.email : '',
            phone: isEdit ? leadJSON.mobilePhone : '',
            passport: isEdit ? leadJSON.fax : '',
            passportExpiredDate: isEdit ? leadJSON.endPassport : '',
            visaSubclass: isEdit ? leadJSON.visaSubclass : '',
            visaGrantNumber: isEdit ? leadJSON.visaGrantNumber : '',
            grantExpiredDate: isEdit ? leadJSON.visaGrantExpiredDate : ''
        },
        validationSchema: yup.object({
            firstName: yup.string().required(Utils.Translate("VALIDATE_EMPTY")),
            lastName: yup.string().required(Utils.Translate("VALIDATE_EMPTY")),
            dateOfBirth: yup.date().required(Utils.Translate("VALIDATE_EMPTY")),
            email: yup.string().email('Invalid email format').required(Utils.Translate("VALIDATE_EMPTY")),
            phone: yup.number().required(Utils.Translate("VALIDATE_EMPTY")),
            passport: yup.string(),
            visaSubclass: yup.string(),
            visaGrantNumber: yup.string(),
            passportExpiredDate: yup.date().required(Utils.Translate("VALIDATE_EMPTY")),
            grantExpiredDate: yup.date().required(Utils.Translate("VALIDATE_EMPTY"))
        }),

        //Submit API
        onSubmit: (values, { resetForm }) => {
            if (isSent) {
                setIsSent(false);
                resetForm({ values: '' });
                setIsEdit(false);
            } else {
                if (isError) {
                    setIsSent(true);
                } else {
                    if (isEdit) {
                        let newItem = { ...leadJSON };

                        newItem.firstName = values.firstName;
                        newItem.lastName = values.lastName;
                        newItem.dob = Utils.FormatDateTime(values.dateOfBirth);
                        newItem.email = values.email;
                        newItem.mobilePhone = values.phone;

                        //passport
                        newItem.fax = values.passport;
                        newItem.endPassport = Utils.FormatDateTime(values.passportExpiredDate);

                        newItem.visaSubclass = values.visaSubclass;

                        //visa
                        newItem.visaGrantNumber = values.visaGrantNumber;
                        newItem.visaGrantExpiredDate = Utils.FormatDateTime(values.grantExpiredDate);
                        newItem.ddLanguage = {
                            itemCode: AppGlobal.getLanguage().toUpperCase(),
                            name: AppGlobal.getLanguage().toUpperCase() === "VN" ? "Vietnamese" : "English",
                            description: "",
                            rank: 5,
                            editable: true,
                            inactive: false,
                            createdUser: "Admin",
                            createdDate: "2017-04-25 03:46:21.000",
                            lastModifiedUser: "Admin",
                            lastModifiedDate: "2017-04-25 03:46:21.000",
                            vnName: "",
                            enName: "",
                            vnDescription: "",
                            enDescription: ""
                        }

                        axios.post(config.url + 'save?class=Lead', newItem, { headers: config.headers })
                            .then(res => {
                                setIsSent(true);
                            }).catch(error => console.log(error));
                    } else {
                        let newItem = {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            dob: Utils.FormatDateTime(values.dateOfBirth),
                            email: values.email,
                            mobilePhone: values.phone,

                            //passport
                            fax: values.passport,
                            endPassport: Utils.FormatDateTime(values.passportExpiredDate),

                            visaSubclass: values.visaSubclass,

                            //visa
                            visaGrantNumber: values.visaGrantNumber,
                            visaGrantExpiredDate: Utils.FormatDateTime(values.grantExpiredDate),
                            ddLanguage: {
                                itemCode: AppGlobal.getLanguage().toUpperCase(),
                                name: AppGlobal.getLanguage().toUpperCase() === "VN" ? "Vietnamese" : "English",
                                description: "",
                                rank: 5,
                                editable: true,
                                inactive: false,
                                createdUser: "Admin",
                                createdDate: "2017-04-25 03:46:21.000",
                                lastModifiedUser: "Admin",
                                lastModifiedDate: "2017-04-25 03:46:21.000",
                                vnName: "",
                                enName: "",
                                vnDescription: "",
                                enDescription: ""
                            },
                            ddSalutation: {
                                itemCode: "CHI",
                                name: "Ms.",
                                rank: 10,
                                editable: true,
                                inactive: false,
                                createdUser: "Admin",
                                createdDate: "2017-04-25 04:4:21.000",
                                lastModifiedUser: "admin",
                                lastModifiedDate: "2017-04-05 01:3:44.000",
                                vnName: "",
                                enName: "",
                                vnDescription: "",
                                enDescription: ""
                            },
                            ddSource: {
                                itemCode: "ADS",
                                name: "Quảng cáo",
                                description: "",
                                rank: 10,
                                editable: true,
                                inactive: false,
                                createdUser: "Admin",
                                createdDate: "2017-04-25 03:46:21.000",
                                lastModifiedUser: "Admin",
                                lastModifiedDate: "2017-04-25 03:46:21.000",
                                vnName: "",
                                enName: "",
                                vnDescription: "",
                                enDescription: ""
                            },
                            ddLeadStatus: {
                                itemCode: "NEW",
                                name: "New",
                                rank: 5,
                                editable: true,
                                inactive: false,
                                createdUser: "Admin",
                                createdDate: "2017-04-25 04:04:21.000",
                                lastModifiedUser: "admin",
                                lastModifiedDate: "2017-04-05 04:01:55.000",
                                vnName: "",
                                enName: "",
                                vnDescription: "",
                                enDescription: ""
                            }
                        }
                        console.log(newItem);

                        axios.post(config.url + 'save?class=Lead', newItem, { headers: config.headers })
                            .then(res => {
                                setIsSent(true);
                            }).catch(error => console.log(error));
                    }
                }

                // console.log(leadJSON);
                // let news = formik.values.news;
                // news = [newItem, ...news];
                // console.log(newItem);
                // console.log(JSON.stringify(news, null, 2));
            }
        }
    });

    function isChange(lg) {
        AppGlobal.setLanguage(lg);
    };

    return (
        <>
            {/* <main ref="main"> */}
            <section className="section-shaped" style={{ minHeight: height }}>
                <div className="shape shape-style-1 shape-default">
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                    <span />
                </div>
                <div className="text-center py-sm">
                    <a href="http://tnss.vn/">
                        <img width="80px" src="logo-customer.png" />
                    </a>
                </div>
                <Container className="pb-sm">
                    <Row className="row-grid justify-content-between align-items-center">
                        <Col lg="4">
                            <div>
                                <Link>
                                    <img
                                        className="mr-1"
                                        alt="..."
                                        src="vietnam.png"
                                        height={height / 20}
                                        onClick={() => isChange('vn')}
                                    />
                                </Link>
                                <Link>
                                    <img
                                        alt="..."
                                        src="australia.png"
                                        height={height / 20}
                                        onClick={() => isChange('en')}
                                    />
                                </Link>
                            </div>
                            <h3 className="display-3 text-grey">
                                {Utils.Translate("TITLE")}
                                {/* <span className="text-grey">completed with examples</span> */}
                            </h3>
                            <p className="lead text-grey">
                                {Utils.Translate("REMIND")}
                            </p>
                        </Col>
                        <Col className="mb-lg-auto" lg="7">
                            <Card className="bg-secondary shadow border-0" style={{ minHeight: 1080 }}>
                                <CardBody className="px-lg-5 py-lg-5" style={isSent ? { display: 'none' } : { display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Form
                                        role="form"
                                        id="submitForm"
                                        onSubmit={formik.handleSubmit}
                                    >
                                        <Row>
                                            <Col>
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                    })}
                                                >
                                                    <Label className="text-white">{Utils.Translate("LABEL_FIRST_NAME")} <span style={{ color: '#ff414d' }}>*</span></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-circle-08" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            name="firstName"
                                                            id="firstName"
                                                            placeholder={Utils.Translate("PLACEHOLDER_FIRST_NAME")}
                                                            type="text"
                                                            // defaultValue={formik.values.firstName}
                                                            value={formik.values.firstName}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </InputGroup>
                                                    {formik.errors.firstName && formik.touched.firstName && (
                                                        <p className="validate-error">{formik.errors.firstName}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                    })}
                                                >
                                                    <Label className="text-white">{Utils.Translate("LABEL_LAST_NAME")} <span style={{ color: '#ff414d' }}>*</span></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-circle-08" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            name="lastName"
                                                            id="lastName"
                                                            placeholder={Utils.Translate("PLACEHOLDER_LAST_NAME")}
                                                            type="text"
                                                            value={formik.values.lastName}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </InputGroup>
                                                    {formik.errors.lastName && formik.touched.lastName && (
                                                        <p className="validate-error">{formik.errors.lastName}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                    })}
                                                >
                                                    <Label className="text-white">{Utils.Translate("LABEL_DATE_OF_BIRTH")} <span style={{ color: '#ff414d' }}>*</span></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <Input
                                                            type="date"
                                                            value={isEdit ? Utils.FormatStringToDate(formik.values.dateOfBirth) : formik.values.dateOfBirth}
                                                            onChange={formik.handleChange}
                                                            name="dateOfBirth"
                                                            id="dateOfBirth"
                                                            className={formik.values.dateOfBirth !== "" ? "" : "DateInput_input"}
                                                            placeholder="datetime placeholder"
                                                        />
                                                    </InputGroup>
                                                    {formik.errors.dateOfBirth && formik.touched.dateOfBirth && (
                                                        <p className="validate-error">{formik.errors.dateOfBirth}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                    })}
                                                >
                                                    <Label className="text-white">{Utils.Translate("LABEL_EMAIL")} <span style={{ color: '#ff414d' }}>*</span></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-email-83" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            name="email"
                                                            id="email"
                                                            placeholder="info@example.com"
                                                            type="text"
                                                            value={formik.values.email}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </InputGroup>
                                                    {formik.errors.email && formik.touched.email && (
                                                        <p className="validate-error">{formik.errors.email}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                    })}
                                                >
                                                    <Label className="text-white">{Utils.Translate("LABEL_PHONE_NUMBER")} <span style={{ color: '#ff414d' }}>*</span></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-tablet-button" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            name="phone"
                                                            id="phone"
                                                            placeholder="(028) 3 467954"
                                                            type="text"
                                                            maxLength={11}
                                                            value={formik.values.phone}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </InputGroup>
                                                    {formik.errors.phone && formik.touched.phone && (
                                                        <p className="validate-error">{formik.errors.phone}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {/* Passport */}
                                        <Row>
                                            <Col md="6">
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                    })}
                                                >
                                                    <Label className="text-white">{Utils.Translate("LABEL_PASSPORT_NUMBER")}</Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-circle-08" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            name="passport"
                                                            id="passport"
                                                            placeholder="64531982346"
                                                            type="text"
                                                            maxLength={11}
                                                            addon={false}
                                                            value={formik.values.passport}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </InputGroup>
                                                    {formik.errors.passport && formik.touched.passport && (
                                                        <p className="validate-error">{formik.errors.passport}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <Label className="text-white">{Utils.Translate("LABEL_EXPIRED_DATE")} <span style={{ color: '#ff414d' }}>*</span></Label>
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                    })}
                                                >
                                                    <InputGroup className="input-group-alternative">
                                                        <Input
                                                            type="date"
                                                            value={isEdit ? Utils.FormatStringToDate(formik.values.passportExpiredDate) : formik.values.passportExpiredDate}
                                                            onChange={formik.handleChange}
                                                            name="passportExpiredDate"
                                                            id="passportExpiredDate"
                                                            className={formik.values.passportExpiredDate !== "" ? "" : "DateInput_input"}
                                                            placeholder="date placeholder"
                                                        />
                                                    </InputGroup>
                                                    {formik.errors.passportExpiredDate && formik.touched.passportExpiredDate && (
                                                        <p className="validate-error">{formik.errors.passportExpiredDate}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {/* Visa subclass */}
                                        <FormGroup
                                            className={classnames("mb-3", {
                                            })}
                                        >
                                            <Label className="text-white">{Utils.Translate("LABEL_VISA_SUBCLASS")}</Label>
                                            <InputGroup className="input-group-alternative">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="ni ni-circle-08" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    name="visaSubclass"
                                                    id="visaSubclass"
                                                    placeholder="500"
                                                    type="text"
                                                    maxLength={3}
                                                    value={formik.values.visaSubclass}
                                                    onChange={formik.handleChange}
                                                />
                                            </InputGroup>
                                            {formik.errors.visaSubclass && formik.touched.visaSubclass && (
                                                <p className="validate-error">{formik.errors.visaSubclass}</p>
                                            )}
                                        </FormGroup>

                                        {/* Passport */}
                                        <Row>
                                            <Col md="6">
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                    })}
                                                >
                                                    <Label className="text-white">{Utils.Translate("LABEL_VISA_GRANT_NUMBER")}</Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-circle-08" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            name="visaGrantNumber"
                                                            id="visaGrantNumber"
                                                            placeholder="8546879549357"
                                                            type="text"
                                                            maxLength={13}
                                                            value={formik.values.visaGrantNumber}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </InputGroup>
                                                    {formik.errors.visaGrantNumber && formik.touched.visaGrantNumber && (
                                                        <p className="validate-error">{formik.errors.visaGrantNumber}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                            <Col md="6">
                                                <FormGroup
                                                    className={classnames("mb-3", {
                                                    })}
                                                >
                                                    <Label className="text-white">{Utils.Translate("LABEL_EXPIRED_DATE")} <span style={{ color: '#ff414d' }}>*</span></Label>
                                                    <InputGroup className="input-group-alternative">
                                                        <Input
                                                            type="date"
                                                            value={isEdit ? Utils.FormatStringToDate(formik.values.grantExpiredDate) : formik.values.grantExpiredDate}
                                                            onChange={formik.handleChange}
                                                            name="grantExpiredDate"
                                                            id="grantExpiredDate"
                                                            className={formik.values.grantExpiredDate !== "" ? "" : "DateInput_input"}
                                                            placeholder="date placeholder"
                                                        />
                                                    </InputGroup>
                                                    {formik.errors.grantExpiredDate && formik.touched.grantExpiredDate && (
                                                        <p className="validate-error">{formik.errors.grantExpiredDate}</p>
                                                    )}
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <div className="custom-control custom-checkbox mb-3">
                                            <div className="my-3">
                                                <input
                                                    className="custom-control-input"
                                                    name="customCheck1"
                                                    id="customCheck1"
                                                    type="checkbox"
                                                    defaultChecked={false}
                                                    // checked={customCheck1}
                                                    value={customCheck1}
                                                    onChange={event => setCustomCheck1(event.target.value)}
                                                />
                                                <label className="custom-control-label" htmlFor="customCheck1">
                                                    <span className="text-white">
                                                        {Utils.Translate("CHECKBOX_TEXT_ONE")}
                                                    </span>
                                                </label>
                                            </div>

                                            <div className="my-3">
                                                <input
                                                    className="custom-control-input"
                                                    name="customCheck2"
                                                    id="customCheck2"
                                                    type="checkbox"
                                                    defaultChecked={false}
                                                    // checked={customCheck2}
                                                    value={customCheck2}
                                                    onChange={event => setCustomCheck2(event.target.value)}
                                                />
                                                <label className="custom-control-label" htmlFor="customCheck2">
                                                    <span className="text-white">
                                                        {Utils.Translate("CHECKBOX_TEXT_TWO")}
                                                    </span>
                                                </label>
                                            </div>

                                            <div className="my-3">
                                                <input
                                                    className="custom-control-input"
                                                    name="customCheck3"
                                                    id="customCheck3"
                                                    type="checkbox"
                                                    defaultChecked={false}
                                                    // checked={customCheck3}
                                                    value={customCheck3}
                                                    onChange={event => setCustomCheck3(event.target.value)}
                                                />
                                                <label className="custom-control-label" htmlFor="customCheck3">
                                                    <span className="text-white">
                                                        {Utils.Translate("CHECKBOX_TEXT_THREE")}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <Button
                                                className="my-3"
                                                color="white"
                                                type="Submit"
                                                onChange={formik.handleChange}
                                                disabled={customCheck1 == false || customCheck2 == false || customCheck3 == false ? true : false}
                                            >
                                                <span style={{ color: '#1ab9c7' }}>{Utils.Translate("BUTTON_SUBMIT")}</span>
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                                {/* style={isSent ? { display: 'block' } : { display: 'none' }} */}
                                <CardBody className="px-lg-5 py-lg-5" style={isSent ? { display: 'flex', justifyContent: 'center', alignItems: 'center' } : { display: 'none' }}>
                                    <Form role="form" className="text-center" onSubmit={formik.handleSubmit}>
                                        <h3 className="text-white">{isError ? Utils.Translate("MESSAGE_UNSUBSCRIBED") : Utils.Translate("MESSAGE_THANK_YOU")}</h3>
                                        <Button
                                            className="my-3"
                                            color="white"
                                            type="Submit"
                                            onChange={formik.handleChange}
                                        >
                                            <span>{Utils.Translate("BUTTON_BACK")}</span>
                                        </Button>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* </main> */}
        </>
    );
}
