import React from "react";

//component
import FormPage from "./Pages/FormPage";

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <main ref="main">
          <FormPage/>
        </main>
      </>
    );
  }
}

export default Index;
