import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss";
import "assets/css/special.css";

import Index from "views/Index.jsx";
import SuccessPage from "views/Pages/SuccessPage.jsx";
import ErrorPage from "views/Pages/ErrorPage.jsx";

ReactDOM.render( <
        BrowserRouter >
        <
        Switch >
        <
        Route path = "/"
        exact render = {
            props => < Index {...props }
            />} / >
            <
            Route path = "/success"
            exact render = {
                props => < SuccessPage {...props }
                />} / >
                <
                Route path = "/error"
                exact render = {
                    props => < ErrorPage {...props }
                    />} / >
                    <
                    Redirect to = "/" / >
                    <
                    /Switch> <
                    /BrowserRouter>,
                    document.getElementById("root")
                );