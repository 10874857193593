const stringQuery = window.location.search;
let language = "VN";

if (stringQuery.includes("language=")) {
    language = stringQuery.substring(stringQuery.search("language=") + 9, stringQuery.search("language=") + 11);
}

let parameters = {
    api_url: "https://app.best.systems/og-crm/api/8.0/",
    debug_mode: false,
    user_token: '',
    language: language.toLowerCase(),
    user_session: {
        user_name: 'Administrator',
        user_tags: [],
        user_roles: [],
        user_employee: '',
        user_language: 'en'
    },
};

const AppGlobal = {
    setParameter: async (key, value) => {
        parameters = { ...parameters };
        parameters[key] = value;
    },
    getParameter: (key) => {
        return parameters[key];
    },

    getLanguage: () => {
        return parameters.language;
    },

    setLanguage: (lg) => {
        AppGlobal.setParameter("language", lg);
    }

    // loadParameters: async () => {
    //     try {
    //        await AsyncStorage.getItem('BSGLOBAL')
    //         .then((value) => parameters = value)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // },

    // getUserToken: () => {
    //     return parameters.user_token;
    // },

    // setUserToken: (token) => {
    //     AppGlobal.setParameter("user_token", token)
    // },

    // getUserName: () => {
    //     return parameters.user_session.user_name ? parameters.user_session.user_name : 'No User';
    // },

    // getUserRoles: () => {
    //     return parameters.user_session.user_roles;
    // },

    // hasRole: (role) => {
    //     if (role == "Login" || role == "App" || role == "Pro" || role == "Home") {
    //         return true;
    //     }
    //     return AppGlobal.getUserRoles().includes(role);
    // },

    // getUserTags: () => {
    //     return parameters.user_session.user_tags;
    // },

    // hasTag: (tag) => {
    //     return true;
    // },

    // getUserLanguage: () => {
    //     return parameters.user_session.user_language;
    // },

    // setUserSession: (session) => {
    //     AppGlobal.setParameter("user_session", session);
    // },

    // getApiURL: () => {
    //     return parameters.api_url;
    // },

    // setApiURL: (url) => {
    //     AppGlobal.setParameter("api_url", url)
    // },
}

export default AppGlobal;