import AppGlobal from './AppGlobal';
import moment from 'moment';

const vi = require('../languages/vi.json');
const en = require('../languages/en.json');

const Cryptr = require('cryptr');
const cryptr = new Cryptr('myTotalySecretKey');

const Utils = {
    Translate: (code) => {
        let language = AppGlobal.getLanguage();
        switch (language) {
            case 'en':
                if (en[code] == undefined) {
                    return code;
                }
                return en[code];
            case 'vn':
                if (vi[code] == undefined) {
                    return code;
                }
                return vi[code];
            default:
                return code;
        }
    },

    FormatDateTime: (date) => {
        let text = moment(date).format('YYYY-MM-DD HH:mm:ss.SSS');
        return text;
    },

    FormatStringToDate: (date) => {
        let text = moment(date).format('YYYY-MM-DD');
        return text;
    },

    DE: (str) => {
        const decryptedString = cryptr.decrypt(str);
        return decryptedString;
    },

    EN: (str) => {
        const encryptedString = cryptr.encrypt(str);
        return encryptedString
    }
}

export { Utils };
