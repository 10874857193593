import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

// nodejs library that concatenates classes
import { Utils } from '../../common/Utils'
import AppGlobal from 'common/AppGlobal'

// reactstrap components
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    Label,
} from "reactstrap";

class SuccessPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 0
        }
    }

    componentDidMount() {
        this.updateHeight()
    }

    updateHeight = () => {
        this.setState({
            height: window.innerHeight
        })
    }

    isChange = (lg) => {
        AppGlobal.setLanguage(lg);
    }

    render() {
        return (
            <>
                <main className="success" ref="main">
                    <section className="section-shaped" style={{ minHeight: this.state.height }}>
                        <div className="shape shape-style-1 shape-default">
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        <div className="text-center py-sm">
                            <img width="80px" src="https://tns.best.systems/VAADIN/themes/admin/assets/images/logo/logo-customer.png" />
                        </div>
                        <Container className="pb-sm">
                            <Row className="row-grid justify-content-between align-items-center">
                                <Col lg="4">
                                    <div>
                                        <Link>
                                            <img
                                                className="mr-1"
                                                alt="..."
                                                src="vietnam.png"
                                                height={this.state.height / 20}
                                                tag={Link}
                                                onClick={() => this.isChange('vi')}
                                            />
                                        </Link>
                                        <Link>
                                            <img
                                                alt="..."
                                                src="australia.png"
                                                height={this.state.height / 20}
                                                onClick={() => this.isChange('en')}
                                            />
                                        </Link>
                                    </div>
                                    <h3 className="display-3 text-grey">
                                        {Utils.Translate("TITLE")}
                                    </h3>
                                    <p className="lead text-grey">
                                        {Utils.Translate("REMIND")}
                                    </p>
                                </Col>
                                <Col className="mb-lg-auto" lg="7">
                                    <Card className="bg-secondary shadow border-0" style={{ minHeight: 1080 }}>
                                        <CardBody className="px-lg-5 py-lg-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <Form role="form" className="text-center">
                                                <h3 className="text-white">{Utils.Translate("MESSAGE_UPDATE_SUCCESS")}</h3>
                                            </Form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
            </>
        );
    }
}

export default SuccessPage;  